*,
::after,
::before {
  box-sizing: border-box;
}
/* fonts */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');
html {
  font-size: 100%;
} /*16px*/

.app__girl-img {
  border-radius: 100%!important;
}

:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #27AAE2;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;
 

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: 'Roboto', sans-serif;
  --bodyFont: 'Nunito', sans-serif;
  --smallText: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  color: #27AAE2;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--smallText);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btn {
  width: 100%;
    color: rgb(255, 255, 255);
    border: transparent;
    height: 60px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transtion);
    background: var(--primary-500);
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

/*
=============== 
Navbar
===============
*/

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}

.no-background{
  background: none; 
}

.nav-header {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-header img {
  width: 200px;
}
.nav-logo {
  display: flex;
  align-items: flex-end;
}

.nav-btn {
  padding: 0.15rem 0.75rem;
}
.nav-btn i {
  font-size: 1.25rem;
}
.nav-links {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: var(--transition);
}
.show-links {
  height: 23.9375rem;
}

.nav-link {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  color: var(--grey-900);
  letter-spacing: var(--letterSpacing);
  padding: 1;
  border-top: 1px solid var(--grey-500);
  transition: var(--transition);
}
.nav-link:hover {
  color: var(--primary-500);
}

.contact-link a {
  padding: 0.15rem 1rem;
}

.app_all {
margin-top: 15px;
}

.app__know {
  margin-bottom: 100px;
}

// .app__equip {
//   margin-top: 40px!important;
// }

@media screen and (max-width:722px) {
  .btn-hover {
    width: 50%;
  }
  .no-background_logo {
    margin-top: -90px!important;
  }

  .follow {
    margin-top: -100px!important;
  }
  

  .app_all {
    background: rgb(255, 255, 255);
}

  #fixed{
    top:0;
}

  .app__equip {
    font-size: 18px;
  }

  .app__know {
    margin-bottom: 60px;
  }
}

.app__banner-img {
  background-image: url(../images/little.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.img-wrapper {
  overflow: hidden;
  color: #8da3d7;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  width: 100%;
}
img.hover-zoom:hover {
  transform: scale(1.25);
}

@media screen and (max-width: 692px) {
  .navbar {
    height: 7.5rem;
  }
}
@media screen and (min-width: 992px) {
  .navbar {
    height: 7rem;
  }

  .no-background_logo {
    margin-top: -140px!important;
  }
  

  .about__vertical {
   background-image: url("../images/about_dann.jpg");
   background-repeat: no-repeat;
   background-position: center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
  }

  .about__vertical {
   
    display: flex;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 10px;
        // border: 3px dashed #236CB4;
        align-items:center;
        justify-content:center;
        padding: 20px;
  }
  .nav-center {
    display: flex;
    align-items: center;
  }
  .nav-header {
    padding: 0;
    margin-right: 2rem;
    height: auto;
  }

  .nav-btn {
    display: none;
  }
  .nav-links {
    height: auto !important;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .nav-link {
    padding: 0;
    border-top: none;
    margin-right: 1rem;
    font-size: 1rem;
  }
  .contact-link {
    margin-top: -12px;
    margin-left: 500px,
  }
}
/*
=============== 
Page
===============
*/

.page {
  width: 90vw;
  max-width: var(--max-width);
  margin: 0 auto;
}
.page {
  padding-top: 2rem;
  min-height: calc(100vh - (6rem + 4rem));
}

.page-aboutUs {
  // // width: 90vw;
  // max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 2rem;
  min-height: calc(80vh - (6rem + 4rem));
}


/*
=============== 
Footer
===============
*/

.page-footer {
  text-align: center;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--black);
  color: var(--white);
}
.page-footer h5 {
  margin-top: 0;
  margin-bottom: 0;
}
.page-footer p {
  margin-bottom: 0;
}
.page-footer .footer-logo,
.page-footer a {
  color: var(--primary-500);
}
/*
=============== 
Hero
===============
*/

.hero {
  height: 60vh;
  position: relative;
  margin-bottom: 2rem;
  background: url('../images/main_rest.jpeg') center/cover no-repeat;
}
.hero-container {
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius);
  margin-top: 80px;
}

.hero-container__school {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius);
  background: rgba(0, 0, 0, 0.4);
}

.hero-text {
  color: var(--white);
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .hero-text h1 {
    font-size: 4rem;
    margin-bottom: 0;
  }
}


.recipes-container {
  display: grid;
  gap: 2rem 1rem;
}

.recipes-list {
  display: grid;
  gap: 2rem 1rem;
  padding-bottom: 3rem;
}
.tags-container {
  order: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  /* background: blue; */
}
.tags-container h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.tags-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.tags-list a {
  text-transform: capitalize;
  display: block;
  color: var(--grey-500);
  transition: var(--transition);
}
.tags-list a:hover {
  color: var(--primary-500);
}
.recipe {
  display: block;
}
.recipe-img {
  height: 15rem;
  border-radius: var(--borderRadius);
  margin-bottom: 1rem;
}
.recipe h5 {
  margin-bottom: 0;
  margin-top: 0.25rem;
  line-height: 1;
  color: var(--grey-700);
}
.recipe p {
  margin-bottom: 0;
  line-height: 1;
  color: var(--grey-500);
  margin-top: 0.5rem;
  letter-spacing: var(--letterSpacing);
}
@media screen and (min-width: 576px) {
  .recipes-list {
    grid-template-columns: 1fr 1fr;
  }
  .recipe-img {
    height: 10rem;
  }
}

@media screen and (min-width: 992px) {
  .recipes-container {
    grid-template-columns: 200px 1fr;
    gap: 1rem;
  }
  .recipes-list {
    grid-template-columns: 1fr 1fr;
  }

  .recipe p {
    font-size: 0.85rem;
  }
  .tags-container {
    order: 0;
    /* background: blue; */
  }
  .tags-list {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .recipe h5 {
    font-size: 1.15rem;
  }
  .recipes-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .app__img-about {
    margin-left: 100px;
    width: 80%;
    margin-top: -100px;
  }
}
/*
=============== 
Error
===============
*/
.error-page {
  text-align: center;
  padding-top: 5rem;
}
.error-page h1 {
  font-size: 9rem;
}
/*
=============== 
About Page
===============
*/

.btn-hover:hover {
  color:#fff
}

.about-page h2 {
  text-transform: none;
  font-weight: bold;
}
.about-page {
  display: grid;
  gap: 2rem 4rem;
  padding-bottom: 3rem;
}
.about-img {
  border-radius: var(--borderRadius);
  height: 300px;
}

@media screen and (min-width: 992px) {
  .about-page {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    align-items: center;
    margin-top: 130px;
  }
  .about-img {
    height: 100%;
  }

  .btn-hover {
    width: 25%;
  }
}

.featured-title {
  text-align: center;
}
/*
=============== 
Contact Page
===============
*/
.contact-form {
  width: 100%;
  margin: 0;
}
.contact-container {
  display: grid;
  gap: 2rem 3rem;
  padding-bottom: 3rem;
}
@media screen and (min-width: 992px) {
  .contact-container {
    grid-template-columns: 1fr 450px;
    align-items: center;
  }
}

/*
=============== 
Tags Page
===============
*/

.tags-wrapper {
  display: grid;
  gap: 2rem;
  padding-bottom: 3rem;
}
.tag {
  background: var(--grey-500);
  border-radius: var(--borderRadius);
  text-align: center;
  color: var(--white);
  transition: var(--transition);
  padding: 0.75rem 0;
}
.tag:hover {
  background: var(--primary-500);
}
.tag h5,
.tag p {
  margin-bottom: 0;
}
.tag h5 {
  font-weight: 600;
}
@media screen and (min-width: 576px) {
  .tags-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 992px) {
  .tags-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*
=============== 
Recipe Template
===============
*/

.recipe-hero {
  display: grid;
  gap: 3rem;
}

.recipe-hero-img {
  height: 300px;
  border-radius: var(--borderRadius);
}
.recipe-info p {
  color: var(--grey-600);
}
.recipe-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
  text-align: center;
}
.recipe-icons i {
  font-size: 1.5rem;
  color: var(--primary-500);
}
.recipe-icons h5,
.recipe-icons p {
  margin-bottom: 0;
  font-size: 0.85em;
}

.recipe-icons p {
  color: var(--grey-600);
}
.recipe-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.7em;
  font-weight: 600;
}
.recipe-tags a {
  background: var(--primary-500);
  border-radius: var(--borderRadius);
  color: var(--white);
  padding: 0.05rem 0.5rem;
  margin: 0 0.25rem;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .recipe-hero {
    grid-template-columns: 4fr 5fr;
    align-items: center;
  }
}
.recipe-content {
  padding: 3rem 0;
  display: grid;
  gap: 2rem 5rem;
}
@media screen and (min-width: 992px) {
  .recipe-content {
    grid-template-columns: 2fr 1fr;
  }
}
.single-instruction header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  align-items: center;
}
.single-instruction header p {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-500);
  margin-bottom: 0;
}
.single-instruction > p {
  color: var(--grey-700);
}

.single-instruction header div {
  height: 2px;
  background: var(--grey-300);
}

.second-column {
  display: grid;
  row-gap: 2rem;
}

.single-ingredient {
  border-bottom: 2px solid var(--grey-300);
  padding-bottom: 0.75rem;
  color: var(--grey-700);
}
.single-tool {
  border-bottom: 2px solid var(--grey-300);
  padding-bottom: 0.75rem;
  color: var(--primary-500);
  text-transform: capitalize;
}


.top-to-btm{
  position: relative;
}

.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style{
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 5s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: #fff;
  color: #062751;
  border: 2px solid #092768;
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}